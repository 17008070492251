export const linkResolver = doc => {
  if (!doc) {
    return '/'
  }

  switch (doc.type) {
    // case 'home':
    //   return '/'
    case 'page':
      return `/${doc.lang.slice(0, 2)}/${doc.uid}`

    default:
      if (doc.uid) {
        return `/${doc.lang.slice(0, 2)}/article/${doc.uid}`
      }

      return `/${doc.type}`
  }
}
