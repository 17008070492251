module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"AHA Paris","short_name":"AHA","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui"},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.aha-paris.com/"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-#####"},
    },{
      plugin: require('../node_modules/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"aha-site","pages":[{"type":"Article","match":"/en/article/:uid","path":"/en/article","component":"/opt/build/repo/src/templates/article/index.en.js"},{"type":"Article","match":"/fr/article/:uid","path":"/fr/article","component":"/opt/build/repo/src/templates/article/index.fr.js"},{"type":"Page","match":"/en/:uid","path":"/page","component":"/opt/build/repo/src/templates/page/index.en.js"},{"type":"Page","match":"/fr/:uid","path":"/page","component":"/opt/build/repo/src/templates/page/index.fr.js"},{"type":"Homepage","match":"/fr/","path":"/fr","component":"/opt/build/repo/src/templates/index.fr.js"},{"type":"Homepage","match":"/en/","path":"/en","component":"/opt/build/repo/src/templates/index.en.js"},{"type":"Article","match":"/fr/events","path":"/fr/events","component":"/opt/build/repo/src/templates/events.fr.js"},{"type":"Article","match":"/en/events","path":"/en/events","component":"/opt/build/repo/src/templates/events.en.js"},{"type":"Header","match":"/fr/contact","path":"/fr/contact","component":"/opt/build/repo/src/templates/contact.fr.js"},{"type":"Header","match":"/en/contact","path":"/en/contact","component":"/opt/build/repo/src/templates/contact.en.js"}],"accessToken":"MC5YeGRmc3hFQUFDTUEySmU5.Mnzvv70UK--_ve-_vSEQS--_ve-_vWnvv73vv71H77-977-977-9Yu-_ve-_vTzvv70J77-9BVtdMu-_vRQ","previews":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
